.PopularCategories {
    .backgroundRight {
        top: 40%;
    }

    .container {
        .row {
            .colLeft {
                margin-bottom: 2rem;

                .card {
                    position: relative;
                    -webkit-backdrop-filter: blur(237.5px);
                    backdrop-filter: blur(237.5px);
                    background: linear-gradient(180deg, rgba(1, 163, 255, .05), rgba(1, 163, 255, .17));

                    &::before {
                        background: linear-gradient(360deg, #01a3ff .28%, rgba(1, 163, 255, 0) 62.74%);
                        border-radius: 10px;
                        content: "";
                        inset: 0;
                        -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
                        -webkit-mask-composite: xor;
                        mask-composite: exclude;
                        opacity: .7;
                        padding: 1.6px;
                        position: absolute;
                        transition: opacity 3s ease;
                        -webkit-transition: opacity 3s ease;
                        -moz-transition: opacity 3s ease;
                        -o-transition: opacity 3s ease;
                    }

                    .img {
                        object-fit: contain;
                        aspect-ratio: 2/2;
                        width: 100%;
                    }

                    .cardBody {
                        .title {
                            font-weight: bold;
                            font-size: 1.5rem;
                            text-transform: uppercase;
                            line-height: 30px;
                            font-style: normal;
                            background: linear-gradient(270.41deg, #00a3ff 16.58%, #9bffff 113.09%);
                            color: transparent;
                            -webkit-background-clip: text;
                            background-clip: text;
                            will-change: transform;
                            overflow: hidden;

                            @media (max-width: 991px) {
                                font-size: 16px;
                            }
                        }

                        .text {
                            font-weight: normal;
                            font-size: 1rem;
                            line-height: 25px;
                            font-style: normal;
                        }

                        .button {
                            height: 45px;
                            width: 200px;
                        }

                    }
                }
            }


        }
    }
}