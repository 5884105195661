.Contactus {
    .backgroundRight {
        top: -15%;
    }

    .backgroundLeft {
        top: 100%;
    }

    .container {
        .row {
            display: flex;
            justify-content: space-between;
            align-items: center;

            // Form 
            .form_col {
                border: 2px solid rgb(92, 255, 231);
                padding: 2rem 1rem;

                .form {
                    margin-top: 3rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    @media (max-width: 991px) {
                        margin-top: 0;
                    }

                    .formGroup {
                        width: 50%;
                    }

                    .formLabel {
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 22px;

                        span {
                            color: red;
                            margin-left: 3px;
                        }
                    }

                    .formControlText {
                        background: transparent;
                        background-color: transparent;
                        border: 1px solid rgb(92, 255, 231);
                        box-sizing: border-box;
                        border-radius: 4px;
                        width: 100%;
                        padding: 0.65rem 1rem;
                        color: white;

                        &:active {
                            background: transparent;
                            background-color: transparent;
                        }
                    }

                    .description {
                        resize: none;
                        display: block;
                    }

                    .formControlInputContact {
                        background: transparent;
                        background-color: transparent;
                        border: 1px solid rgb(92, 255, 231);
                        box-sizing: border-box;
                        border-radius: 4px;
                        width: 100%;
                        padding: 0.65rem 1rem;
                        padding-left: 4rem;
                        color: white;
                    }

                    & ::placeholder {
                        color: #9f9f9f;
                    }

                    .formInputWrap {
                        position: relative;
                    }

                    .submitBtn {
                        height: 60px;
                        width: 100%;
                    }
                }
            }

            // Social 

            .social {
                @media (max-width: 991px) {
                    margin-top: 2rem;
                }

                .TESTIMONIALS {
                    font-size: 1rem;
                    color: rgb(92, 255, 231);
                }

                .get {
                    font-size: 3rem;

                    .touch {
                        opacity: 0.6;
                        transition: 0.9s;
                        color: rgb(92, 255, 231);
                        cursor: pointer;

                        &:active,
                        &:hover {
                            color: white;
                            color: rgb(92, 255, 231);
                            text-decoration: none;
                            opacity: 1
                        }
                    }
                }

                // Social Links 
                .link_row {
                    margin-bottom: 1.5rem;
                    margin-top: 1rem;

                    .linkcol {
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        .link {
                            .icon {
                                font-size: 2.5rem;
                                color: rgb(92, 255, 231);
                            }
                        }
                    }

                    .address_col {
                        .text {
                            font-size: 22px;
                            font-weight: bold;
                            color: white;
                            text-decoration: none;
                            margin-bottom: 0;
                        }

                        .link {
                            font-size: 16px;
                            font-weight: bold;
                            color: white;
                            text-decoration: none;
                            cursor: pointer;

                            &:active,
                            &:hover {
                                color: rgb(92, 255, 231);
                                text-decoration: underline;

                            }
                        }


                    }
                }
            }
        }
    }
}