.RentalServices {
    padding-bottom: 8rem;

    .container {
        background: linear-gradient(90deg,
                rgba(217, 217, 217, 0.06) 0%,
                rgba(217, 217, 217, 0.05) 100%);

        .row {
            .column_left {
                padding: 5rem 4rem;

                @media (max-width: 991px) {
                    padding: 1rem;

                }

                .heading_one {
                    font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
                }

                .button {
                    width: 164.36px;
                }

                .button_one {
                    margin-right: 20px;
                }
            }

            .column_right {
                position: relative;
                width: 40%;

                .readyToMakeBanner {
                    position: absolute;
                    bottom: 0;
                    right: -7%;
                    max-width: 521px;
                    width: 100%;
                }
            }
        }
    }
}

.ReadyToMakeMobView {
    .container {
        background: linear-gradient(90deg,
                rgba(217, 217, 217, 0.06) 0%,
                rgba(217, 217, 217, 0.05) 100%);

        .row {
            .column_left {
                padding: 2rem 4rem;

                .heading_one {
                    font-family: "eurostileextended-roman-dtc";
                    font-size: 16px;
                    line-height: 20px;
                }

                .button {
                    width: 111.9px;
                }

                .button_one {
                    margin-right: 20px;
                }
            }

            .column_right {
                position: relative;
                width: 40%;

                .readyToMakeBannerMobView {
                    position: absolute;
                    bottom: 0;
                    right: -21%;
                    width: 70%;
                }
            }
        }
    }
}

@media (max-width: 576px) {
    .ReadyToMakeMobView {
        padding-bottom: 0;
        padding-top: 0;
        display: block;

        .container {
            .row {
                margin-right: 0.25rem;

                .column_left {
                    padding: 1.5rem 0 2rem 3rem;
                }

                .column_right {
                    position: relative;
                    width: 45%;

                    .readyToMakeBannerMobView {
                        position: absolute;
                        bottom: 0;
                        position: absolute;
                        bottom: 0;
                        right: -125%;
                        width: 65%;
                    }
                }
            }
        }
    }
}

@media (min-width: 768px) {
    .ReadyToMake {
        display: block;
    }

    .ReadyToMakeMobView {
        display: none;
    }
}

@media (max-width: 767px) {
    .ReadyToMake {
        display: none;
    }

    .ReadyToMakeMobView {
        display: block;

        .container {
            .row {
                .column_left {

                    // padding: 3.5rem 2rem;
                    .heading_one {
                        width: 100%;
                    }
                }
            }
        }
    }
}

@media (max-width: 991px) {
    .ReadyToMake {
        .container {
            .row {
                .column_left {
                    .heading_one {
                        font-size: 23px;
                        line-height: 28px;
                        width: 70%;
                    }

                    .button {
                        width: 111.9px;
                    }
                }
            }
        }
    }
}

@media (max-width: 1200px) {
    .ReadyToMake {
        .container {
            .row {
                .column_left {
                    padding: 2rem 2rem;
                }

                .column_right {
                    .readyToMakeBanner {
                        bottom: 5%;
                    }
                }
            }
        }
    }
}