.Problems {
    .container {
        .row {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .colLeft {
                .title {
                    font-size: calc(1rem + 1.5vw);
                    line-height: calc(2rem + 1.5vw);
                    color: rgb(92, 255, 231);
                    font-weight: 5;
                }

                .description {
                    font-size: calc(.5rem + .5vw);
                    line-height: calc(1rem + .5vw);
                    font-weight: 5;

                }
            }

            .colRight {
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;

                @media (max-width: 991px) {
                    margin-top: 2rem;
                }

                .dataRow {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: 1px solid rgb(92, 255, 231);
                    border-radius: 150px;
                    background-color: transparent;
                    padding: 1rem;

                    &:active,
                    &:hover {
                        color: black;
                        background: linear-gradient(135deg, rgb(104, 253, 244), rgb(0, 153, 130)) rgb(0, 0, 0);
                        outline: transparent solid 0;
                        border: transparent;
                        transition-duration: 600ms;
                        transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;

                        .col {
                            svg {
                                color: white;
                            }
                        }
                    }

                    .col {
                        svg {
                            font-size: 4rem;
                            color: rgb(92, 255, 231);
                        }

                        p {
                            margin-bottom: 0;
                        }
                    }

                    .colLast {
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                    }
                }
            }

        }
    }
}