.footer {
  background-color: black;
  color: white;
  padding-top: 8rem;
  padding-bottom: 1rem;

  @media (max-width: 991px) {
    padding-top: 1rem;

  }

  .backgroundRight {
   bottom: 0;
  }

  .container {
    text-align: center;

    .logo_row {
      display: flex;
      justify-content: center;
      align-items: center;

      .logo {
        width: 100px;
        height: 100px;
      }
    }

    .link_row {
      .column_link {
        margin: 3rem auto;

        .list_wrap {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 0;

          @media (max-width: 991px) {
            display: block;
            justify-content: start;
            padding-left: 0;

          }

          li {
            list-style: none;
            text-decoration: none;
            margin: auto 40px;

            a {
              text-decoration: none;
              color: rgb(92, 255, 231);
              font-size: 20px;

              &:active,
              &:hover {
                font-weight: bolder;
                color: white;
                text-decoration: underline;

              }
            }
          }
        }

        .text {
          margin: 1rem auto;
          width: 60%;
          text-align: center;
          font-size: 22px;
        }
      }

      // Social Links
      .column_social {
        margin-bottom: 2rem;

        .column_wrap {
          a {
            margin: auto 30px;

            @media (max-width: 576px) {
              margin: auto 10px;
            }

            .social_icon {
              font-size: 22px;

              color: rgb(92, 255, 231);

              &:active,
              &:hover {
                font-weight: bolder;
                color: white;
                text-decoration: underline;

              }
            }
          }
        }
      }
    }

    .col {
      text-align: center;

      .logo {
        width: 100px;
        height: 100px;
      }
    }
  }

  // copyrights
  .copyrights {
    text-align: center;

    hr {
      color: rgb(92, 255, 231)
    }

    hr:not([size]) {
      height: 2px;
    }

    .row {
      .col {
        .text {
          font-size: 14px;
          color: rgb(92, 255, 231);
          margin-bottom: 0;

          &:active,
          &:hover {
            font-weight: bolder;
            color: white;
            cursor: pointer;

          }
        }
      }
    }
  }
}