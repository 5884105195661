.CitiesServices {
  padding: 40px 0 80px !important;

  @media (max-width: 991px) {
    padding-bottom: 30px !important;
  }

  .containerWrapper {
    position: relative;

    .container {
      position: relative;
      z-index: 5;

      .row {
        .col {
          text-align: center;

          .title {
            font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
            font-style: normal;
            font-weight: 5;
            font-size: 35px;
            line-height: 45px;
            background: linear-gradient(270.41deg,
                #00fff0 0.93%,
                #10ff70 113.09%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
            max-width: 80%;
            margin: 0 auto 10px;

            @media (max-width: 1440px) {
              font-size: calc(1.1rem + 1.2vw);
            }

            @media (max-width: 991px) {
              font-size: calc(1.2rem + 1.2vw);
              line-height: 27px;
              max-width: 100%;
            }

            @media (max-width: 767px) {
              text-align: left;
              margin-left: 0;
              font-size: calc(1rem + 1.2vw);
            }
          }





          .subTitle {
            font-family: "Poppins Bold", sans-serif;
            font-weight: 300;
            font-size: 18px;
            line-height: 30px;
            margin: 0 auto 50px;
            max-width: 80%;

            @media (max-width: 767px) {
              font-size: 16px;
              max-width: 100%;
              margin-bottom: 40px;
              text-align: left;
              margin-left: 0;
            }
          }

        }
      }

      .cardsRow {
        .cardCol {
          @media (max-width: 767px) {
            --bs-gutter-y: 2rem;
          }

          .skyRocketCard {
            min-height: 428.56px;
            max-width: 341.59px;
            height: 100%;
            margin: 0 auto;

            h3 {
              font-size: 18px;
              line-height: 20px;
              font-family: "Poppins Bold", sans-serif;
              font-weight: 700;
            }

            p {
              max-width: 86%;
              font-family: "Poppins Bold", sans-serif;
              font-weight: 300;
              font-size: 16px;
              line-height: 27px;
            }

            &:hover {

              .rightCardCircle,
              .leftCardCircle {
                opacity: 1;
              }
            }

            @media (max-width: 767px) {
              max-width: 348.03px;
              min-height: 335px;

              h3 {
                font-size: 18px;
                line-height: 20px;
                max-width: 100%;
                font-family: "Poppins Bold", sans-serif;
                font-weight: 700;
              }
            }
          }

          :global {
            .cardWrapperCommon {
              padding: 25px 10px 10px 25px;
            }

            .CitiesCardWrapper {
              background: #0e0e2b;
              border-radius: 21.7231px;
              position: relative;

              h3 {
                margin: 18px 0;
              }

              &::before {
                content: "";
                position: absolute;
                inset: 0;
                border-radius: 21.7231px;
                padding: 1.73px;
                background: linear-gradient(140deg,
                    rgba(255, 255, 255, 0.5),
                    rgba(255, 255, 255, 0.03),
                    rgba(255, 255, 255, 0.5));
                -webkit-mask: linear-gradient(#fff 0 0) content-box,
                  linear-gradient(#fff 0 0);
                -webkit-mask-composite: xor;
                mask-composite: exclude;
                opacity: 0.7;
                transition: opacity 0.5s ease-in-out;
                -webkit-transition: opacity 0.5s ease-in-out;
                -moz-transition: opacity 0.5s ease-in-out;
                -o-transition: opacity 0.5s ease-in-out;
              }

              &:hover {
                &::before {
                  background: linear-gradient(140deg,
                      rgba(68, 210, 251, 1),
                      rgba(255, 255, 255, 0.03),
                      rgba(36, 84, 234, 1));
                  opacity: 1;
                }
              }

              hr {
                display: none;
              }
            }

            .skyRocketCardWrapper1 {
              background: linear-gradient(0deg,
                  rgba(255, 0, 153, 0.17) 0%,
                  rgba(255, 0, 153, 0.05) 100%);
              border-radius: 10px;
              backdrop-filter: blur(237.5px);

              h3 {
                font-family: "Poppins Bold", sans-serif;
                color: #ff0099;
                font-weight: 700;
                font-size: 20px;
                max-width: 90%;
              }

              p {
                font-weight: 300;
                font-size: 16px;
                line-height: 27px;
                font-family: "Poppins Bold", sans-serif;
              }

              hr {
                border: none;
                height: 1px;
                background: linear-gradient(270deg,
                    rgba(255, 0, 153, 0.13) 1.33%,
                    #ff0099 64.91%,
                    rgba(255, 0, 153, 0.1) 100%);
                opacity: 1;
              }

              &::before {
                content: "";
                position: absolute;
                inset: 0;
                border-radius: 10px;
                padding: 1.6px;
                background: linear-gradient(90deg,
                    #ff0099 0.07%,
                    rgba(255, 0, 153, 0) 62.46%);
                -webkit-mask: linear-gradient(#fff 0 0) content-box,
                  linear-gradient(#fff 0 0);
                -webkit-mask-composite: xor;
                mask-composite: exclude;
                opacity: 0.7;
                transition: opacity 3s ease;
                -webkit-transition: opacity 3s ease;
                -moz-transition: opacity 3s ease;
                -o-transition: opacity 3s ease;
              }
            }

            .skyRocketCardWrapper2 {
              background: linear-gradient(0deg,
                  rgba(255, 0, 0, 0.17) 0%,
                  rgba(255, 0, 0, 0.05) 100%);
              border-radius: 10px;
              backdrop-filter: blur(237.5px);

              h3 {
                font-family: "Poppins Bold", sans-serif;
                color: #ff0000;
                font-weight: 700;
                font-size: 20px;
                max-width: 90%;
              }

              p {
                font-weight: 300;
                font-size: 16px;
                line-height: 27px;
                font-family: "Poppins Bold", sans-serif;
              }

              hr {
                border: none;
                height: 1px;
                background: linear-gradient(270deg,
                    rgba(255, 0, 0, 0.13) 1.33%,
                    #ff0000 64.91%,
                    rgba(255, 0, 0, 0.1) 100%);
                opacity: 1;
              }

              &::before {
                content: "";
                position: absolute;
                inset: 0;
                border-radius: 10px;
                padding: 1.6px;
                background: linear-gradient(90deg,
                    #ff0000 0.07%,
                    rgba(255, 0, 0, 0) 62.46%);
                -webkit-mask: linear-gradient(#fff 0 0) content-box,
                  linear-gradient(#fff 0 0);
                -webkit-mask-composite: xor;
                mask-composite: exclude;
                opacity: 0.7;
                transition: opacity 3s ease;
                -webkit-transition: opacity 3s ease;
                -moz-transition: opacity 3s ease;
                -o-transition: opacity 3s ease;
              }
            }

            .skyRocketCardWrapper3 {
              background: linear-gradient(240deg,
                  rgba(255, 228, 0, 0.05) 0%,
                  rgba(255, 228, 0, 0.17) 100%);
              border-radius: 10px;
              backdrop-filter: blur(237.5px);

              h3 {
                font-family: "Poppins Bold", sans-serif;
                color: #ffe400;
                font-weight: 700;
                font-size: 20px;
                max-width: 90%;
              }

              p {
                font-weight: 300;
                font-size: 16px;
                line-height: 27px;
                font-family: "Poppins Bold", sans-serif;
              }

              hr {
                border: none;
                height: 1px;
                background: linear-gradient(270deg,
                    rgba(255, 228, 0, 0.13) 1.33%,
                    #ffe400 64.91%,
                    rgba(255, 228, 0, 0.1) 100%);
                opacity: 1;
              }

              &::before {
                content: "";
                position: absolute;
                inset: 0;
                border-radius: 10px;
                padding: 1.6px;
                background: linear-gradient(90deg,
                    #ffe400 0.28%,
                    rgba(255, 228, 0, 0) 62.74%);
                -webkit-mask: linear-gradient(#fff 0 0) content-box,
                  linear-gradient(#fff 0 0);
                -webkit-mask-composite: xor;
                mask-composite: exclude;
                opacity: 0.7;
                transition: opacity 3s ease;
                -webkit-transition: opacity 3s ease;
                -moz-transition: opacity 3s ease;
                -o-transition: opacity 3s ease;
              }
            }

            .skyRocketCardWrapper4 {
              background: linear-gradient(200deg,
                  rgba(117, 250, 146, 0.05) 0%,
                  rgba(117, 250, 146, 0.17) 100%);
              border-radius: 10px;
              backdrop-filter: blur(237.5px);

              h3 {
                font-family: "Poppins Bold", sans-serif;
                color: #75fa92;
                font-weight: 700;
                font-size: 20px;
                max-width: 90%;
              }

              p {
                font-family: "Poppins Bold", sans-serif;
                font-weight: 300;
                font-size: 16px;
                line-height: 27px;
              }

              hr {
                border: none;
                height: 1px;
                background: linear-gradient(270deg,
                    rgba(117, 250, 146, 0.13) 1.33%,
                    #75fa92 64.91%,
                    rgba(117, 250, 146, 0.1) 100%);
                opacity: 1;
              }

              &::before {
                content: "";
                position: absolute;
                inset: 0;
                border-radius: 10px;
                padding: 1.6px;
                background: linear-gradient(90deg,
                    #75fa92 0.28%,
                    rgba(117, 250, 146, 0) 62.74%);
                -webkit-mask: linear-gradient(#fff 0 0) content-box,
                  linear-gradient(#fff 0 0);
                -webkit-mask-composite: xor;
                mask-composite: exclude;
                opacity: 0.7;
                transition: opacity 3s ease;
                -webkit-transition: opacity 3s ease;
                -moz-transition: opacity 3s ease;
                -o-transition: opacity 3s ease;
              }
            }

            .skyRocketCardWrapper5 {
              background: linear-gradient(180deg,
                  rgba(1, 163, 255, 0.05) 0%,
                  rgba(1, 163, 255, 0.17) 100%);
              border-radius: 10px;
              // opacity: 0.87;
              // transition: opacity 0.3s ease-out;
              // -webkit-transition: opacity 0.3s ease-out;
              // -moz-transition: opacity 0.3s ease-out;
              // -o-transition: opacity 0.3s ease-out;
              // transition-delay: 0.4s;
              backdrop-filter: blur(237.5px);

              h3 {
                font-family: "Poppins Bold", sans-serif;
                color: #01a3ff;
                font-weight: 700;
                font-size: 20px;
                max-width: 90%;
              }

              p {
                font-weight: 300;
                font-family: "Poppins Bold", sans-serif;
                font-size: 16px;
                line-height: 27px;
              }

              hr {
                border: none;
                height: 1px;
                background: linear-gradient(270deg,
                    rgba(1, 163, 255, 0.13) 1.33%,
                    #01a3ff 64.91%,
                    rgba(1, 163, 255, 0.1) 100%);
                opacity: 1;
              }

              &::before {
                content: "";
                position: absolute;
                inset: 0;
                border-radius: 10px;
                padding: 1.6px;
                background: linear-gradient(90deg,
                    #01a3ff 0.28%,
                    rgba(1, 163, 255, 0) 62.74%);
                -webkit-mask: linear-gradient(#fff 0 0) content-box,
                  linear-gradient(#fff 0 0);
                -webkit-mask-composite: xor;
                mask-composite: exclude;
                opacity: 0.7;
                transition: opacity 3s ease;
                -webkit-transition: opacity 3s ease;
                -moz-transition: opacity 3s ease;
                -o-transition: opacity 3s ease;
              }
            }

            .skyRocketCardWrapper6 {
              background: linear-gradient(0deg,
                  rgba(219, 0, 255, 0.17) 0%,
                  rgba(219, 0, 255, 0.05) 100%);
              border-radius: 10px;
              backdrop-filter: blur(237.5px);

              h3 {
                font-family: "Poppins Bold", sans-serif;
                color: #db00ff;
                font-weight: 700;
                font-size: 20px;
                max-width: 90%;
              }

              p {
                font-family: "Poppins Bold", sans-serif;
                font-weight: 300;
                font-size: 16px;
                line-height: 27px;
              }

              hr {
                border: none;
                height: 1px;
                background: linear-gradient(270deg,
                    rgba(219, 0, 255, 0.13) 1.33%,
                    #db00ff 64.91%,
                    rgba(219, 0, 255, 0.1) 100%);
                opacity: 1;
              }

              &::before {
                content: "";
                position: absolute;
                inset: 0;
                border-radius: 10px;
                padding: 1.6px;
                background: linear-gradient(90deg,
                    #db00ff 0.07%,
                    rgba(219, 0, 255, 0) 62.46%);
                -webkit-mask: linear-gradient(#fff 0 0) content-box,
                  linear-gradient(#fff 0 0);
                -webkit-mask-composite: xor;
                mask-composite: exclude;
                opacity: 0.7;
                transition: opacity 3s ease;
                -webkit-transition: opacity 3s ease;
                -moz-transition: opacity 3s ease;
                -o-transition: opacity 3s ease;
              }
            }
          }
        }
      }
    }
  }
}