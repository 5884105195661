.homeMain {
    .backgroundLeft {
        top: 80%;
    }

    .container {
        background-repeat: no-repeat;
        background-size: cover;
        border: 2px solid #494e5c;
        border-radius: 28px;

        .row {
            .col {
                padding: 6rem 0 5rem 3rem;
                text-align: center;

                @media (max-width: 991px) {
                    padding: 2rem 1rem;
                }

                .hi {
                    font-size: 58px;
                    font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
                    color: transparent;
                    -webkit-background-clip: text;
                    background-clip: text;
                    background-image: linear-gradient(180deg, #ffb6ff, #b344ff);
                    will-change: transform;
                    overflow: hidden;

                    @media (max-width: 991px) {
                        font-size: 28px;
                        margin-bottom: 0;
                    }
                }

                .heading {
                    font-size: 48px;
                    font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
                    color: transparent;
                    -webkit-background-clip: text;
                    background-clip: text;
                    background-image: linear-gradient(180deg, #ffb6ff, #b344ff);
                    will-change: transform;
                    overflow: hidden;

                    @media (max-width: 991px) {
                        font-size: 28px;
                        margin-bottom: 0;
                    }
                }

                .title {
                    font-size: 28px;
                    font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
                    color: transparent;
                    -webkit-background-clip: text;
                    background-clip: text;
                    background-image: linear-gradient(180deg, #ffb6ff, #b344ff);
                    will-change: transform;
                    overflow: hidden;

                    @media (max-width: 991px) {
                        font-size: 18px;
                        margin-bottom: 0;
                    }
                }
            }

            .img_col {
                background: linear-gradient(90deg, rgba(24, 30, 45, 0), #162d40);
                border-radius: 28px;
                position: relative;
                padding-bottom: 1rem;
                padding-top: 1rem;

                .flower {
                    width: 90%;
                    animation: animate 80s linear infinite;

                    @media (max-width: 1280px) {
                        max-width: 500px;
                        width: 80%;
                    }
                }
            }
        }
    }
}

@keyframes animate {
    100% {
        transform: rotate(-360deg);
    }
}