.pricingMain {
  .container {
    .headingOne {
      font-weight: 600;
      font-size: 3.5rem;
      margin-bottom: 2rem;
      text-align: start;
      @media (max-width: 991px) {
        font-size: 2rem;
      }
    }
    .row {
      .colLeft {
        position: relative;
        .swiperSlide {
          .colText {
            .img {
                cursor: pointer;
              height: 772px;
              width: 405px;
              // margin-right: 20px;
              border-radius: 30px;
              // object-fit: contain;
              // aspect-ratio: 2/2;
              transition: 2s ease-in-out;
              &:hover {
                transform: scale(0.9);
              }
            }
            .colWrap {
                 position: absolute;
                 top: 40px;
                 left: 40px;
              .title {
                font-size: 24px;
                font-family: SF Pro Display, SF Pro Icons, Helvetica Neue,
                  Helvetica, Arial, sans-serif;
                color: transparent;
                -webkit-background-clip: text;
                background-clip: text;
                background-image: linear-gradient(180deg, #ffb6ff, #b344ff);
                will-change: transform;
                overflow: hidden;
                font-weight: 600;
                @media (max-width: 991px) {
                  font-size: 28px;
                  margin-bottom: 0;
                }
              }
              .subTitle {
                font-size: 24px;
                font-family: SF Pro Display, SF Pro Icons, Helvetica Neue,
                  Helvetica, Arial, sans-serif;
                color: transparent;
                -webkit-background-clip: text;
                background-clip: text;
                background-image: linear-gradient(180deg, #ffb6ff, #b344ff);
                will-change: transform;
                overflow: hidden;
                font-weight: 600;
                @media (max-width: 991px) {
                  font-size: 28px;
                  margin-bottom: 0;
                }
              }
            }
          }
        }
      }
    }
  }
  :global {
    .swiper-wrapper {
      margin-left: calc(40% - 585px);
      @media (max-width: 991px) {
        margin-left: calc(45% - 585px);
      }
    }
  }
}
