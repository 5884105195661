@import 'bootstrap/dist/css/bootstrap.min.css';

html {
    margin: 0;
    padding: 0;
}

body {
    margin: 0;
    padding: 0;
    cursor: default;
    overflow-x: hidden;
    height: 100vh;
    color: white;
}

section {
    padding-top: 6rem;
    padding-bottom: 6rem;

    @media (max-width: 991px) {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
}

section:nth-child(1) {
    padding-top: 5rem;
}

@media (max-width: 991px) {
    section:nth-child(1) {
        padding-top: 4rem;
    }

}

@media (max-width: 576px) {
    .container {
        margin: auto;
        width: 90%;
    }
}

// HEADER BORDER ANIMATION
.header-border-animation {
    --ease-out-expo: cubic-bezier(0.19, 1, 0.22, 1);
    position: relative;
}

.header-border-animation::before,
.header-border-animation::after {
    position: absolute;
    content: "";
    bottom: -0.1rem;
    display: block;
    width: 100%;
    text-decoration: underline;
    text-underline-position: under;
    text-underline-offset: 1px;
    height: 2px;
    transition: 2.1s var(--ease-out-expo);
}

.header-border-animation::before {
    transform: scaleX(0);
    transform-origin: left;
}

.header-border-animation::after {
    transform-origin: right;
    transition-delay: 0.25s;
}

.header-border-animation:hover::before {
    transform: scaleX(1);
    transition-delay: 0.25s;
    background: rgb(92, 255, 231)
}

.header-border-animation:hover::after {
    transform: scaleX(0);
    transition-delay: 0s;
    background: rgb(92, 255, 231)
}

// Hedaing 
.headingOne {
    font-weight: 5;
    font-size: calc(2.3rem + 1.5vw);
    line-height: calc(2.5rem + 1.5vw);
    color: white;
    margin-bottom: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 576px) {
        justify-content: flex-start;
        font-size: calc(1rem + 1.5vw);

    }
}

.heading {
    padding-top: 2rem;
    padding-bottom: 2rem;
    margin: auto;
    border-top: 2px solid rgb(92, 255, 231);
    border-bottom: 2px solid rgb(92, 255, 231);
    font-size: calc(1rem + 1.5vw);
    line-height: calc(2rem + 1.5vw);
    color: rgb(92, 255, 231);
    font-weight: 5;
}

// Main Button 
.buttonWrap {
    display: flex;
    justify-content: center;
    align-items: center;

    .button {
        background: linear-gradient(90deg, #043b4d, #3070aa);
        border: transparent !important;
        border-radius: 0 !important;
        border-radius: 0;
        color: #fff !important;
        display: flex;
        font-family: Poppins, sans-serif;
        font-weight: 500 !important;
        justify-content: center;
        position: relative;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;

        &:active,
        &:hover {
            color: black;
            background: transparent;
            // background: linear-gradient(135deg, rgb(104, 253, 244), rgb(0, 153, 130)) rgb(0, 0, 0);
            outline: transparent solid 0;
            border: transparent;
            transition-duration: 600ms;
            transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
        }
    }

}

.ReadyToMake {
    border: 1px solid;
    border-image: linear-gradient(70deg, #41caff, transparent) 1;
}

.ReadyToMake::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: linear-gradient(90deg white, transparent) border-box;
    mask: linear-gradient(white, white) padding-box,
        linear-gradient(white, transparent);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask-composite: exclude;
}

// Backgrounds 
.background {
    position: relative;

    .backgroundLeft,
    .backgroundRight {
        position: absolute;
        max-width: 450px;
        width: 100%;
        min-height: 450px;
        z-index: 0;
        border-radius: 50%;
        background: rgb(104 253 243 / 72%);
        opacity: 0.2;
        filter: blur(95px);
        -webkit-filter: blur(95px);
    }

    .backgroundLeft {
        left: 0;

    }

    .backgroundRight {
        right: 0;
    }
}