.header {
    background-color: #0C0C0C;
    width: 100%;
    height: 75px;
    position: sticky;
    box-shadow: 0 6px 49px 0 rgb(0 0 0 /100%);
    z-index: 450;

    .navbar {
        .container {
            .navbar_brand {
                .logo {
                    width: 50px;
                }
            }

            .navbar_toggle {}

            .navbar_Collapse {}

            .nav {
                a {
                    margin: auto 10px;
                    font-size: 20px;
                    font-weight: 600;
                    color: white;
                    text-decoration: none;

                    &:active,
                    &:hover {
                        color: transparent;
                        -webkit-background-clip: text;
                        background-clip: text;
                        background-image: linear-gradient(180deg, #ffb6ff, #b344ff);
                        will-change: transform;
                        overflow: hidden;

                    }
                }
            }
        }
    }
}

:global {


    @media (min-width: 992px) {
        .navbar-expand-lg .navbar-collapse {
            justify-content: end !important;
        }

        .navbar-collapse {
            flex-basis: 65% !important;
            flex-grow: 0 !important;
        }
    }

    @media (min-width: 1200px) {
        .navbar-collapse {
            flex-basis: 45% !important;
            // flex-grow: 0 !important;
        }
    }

    .navbar-light .navbar-toggler-icon {
        background-image: url("../../images/toggle.png") !important;
    }

    .navbar-light .navbar-toggler {
        color: transparent !important;
        border-color: transparent !important;
        outline: none !important;

        &:active,
        &:hover {
            color: transparent !important;
            border-color: transparent !important;
            outline: none !important;

        }
    }
}