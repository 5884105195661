.ServicesMain {
    .container {
        background-repeat: no-repeat;
        background-size: cover;
        border: 2px solid #494e5c;
        border-radius: 28px;

        .row {
            .column_right {
                padding: 6rem 0 5rem 3rem;

                @media (max-width: 991px) {
                    padding: 1rem 0 1rem 2rem;
                }

                .car {
                    width: 39%;
                    font-size: 58px;
                    line-height: 60px;
                    font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
                    color: transparent;
                    -webkit-background-clip: text;
                    background-clip: text;
                    color: white;
                    will-change: transform;
                    overflow: hidden;

                    @media (max-width: 991px) {
                        width: 50%;
                        font-size: 35px;
                        line-height: 40px;
                    }

                }

                .renting {
                    -webkit-text-fill-color: transparent;
                    text-fill-color: transparent;
                    background: linear-gradient(90deg, #05b2da -1.44%, #1fc971);
                    -webkit-background-clip: text;
                    background-clip: text;
                    font-family: Poppins Bold, sans-serif;
                    font-weight: 700;
                    font-size: 28px;

                    @media (max-width: 991px) {
                        font-size: 18px;
                    }
                }

                .Services {
                    font-family: Poppins, sans-serif;
                    font-weight: 500;
                    line-height: 24px;
                    width: 65%;

                    @media (max-width: 991px) {
                        width: 95%;
                    }
                }

            }

            .img_col {
                border-radius: 15px;
                padding-bottom: 1rem;
                padding-top: 1rem;
                background: linear-gradient(90deg,
                        #b344ff02,
                        #b344ff21 100%);

                .flower {
                    max-width: 400px;
                    animation: animate 80s linear infinite;

                    @media (max-width: 1280px) {
                        max-width: 500px;
                        width: 80%;
                    }
                }
            }
        }
    }
}

@keyframes animate {
    100% {
        transform: rotate(-360deg);
    }
}