.carPricing {

  .backgroundRight {
    top: 0;
  }

  .backgroundLeft {
    top: 40%;
  }

  .container {

    .row {
      .slider {

        .SwiperSlide {
          position: relative;

          .imgWrap {
            width: 100%;
            max-height: 400px;

            .cardImg {
              height: 100%;
              aspect-ratio: 3/2;
              object-fit: cover;
              width: 100%;
            }
          }

          .column_left {
            position: relative;
            background-color: rgba(0, 0, 0, 0.724);

            .card {
              .textWrap {
                padding: 1rem 2rem;

                .title {
                  font-weight: normal;
                  font-size: calc(.5rem + 1vw);
                }

                .row {
                  column-gap: 2%;

                  .col {
                    padding: 1rem;
                    font-weight: normal;
                    font-size: 1rem;
                    line-height: 25px;
                    font-style: normal;
                    border: 1px solid rgb(92, 255, 231);
                    border-radius: 5px;
                    background-color: transparent;
                    color: white;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;

                    &:active,
                    &:hover {
                      color: black;
                      background: linear-gradient(135deg, rgb(104, 253, 244), rgb(0, 153, 130)) rgb(0, 0, 0);
                      outline: transparent solid 0;
                      border: transparent;
                      transition-duration: 600ms;
                      transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
                    }

                    @media (max-width: 991px) {
                      margin-bottom: 1rem;
                    }

                  }



                  .button {
                    color: white;
                    width: 100%;
                    height: 59px;

                    &:active,
                    &:hover {
                      color: black;
                      background: linear-gradient(135deg, rgb(104, 253, 244), rgb(0, 153, 130)) rgb(0, 0, 0);
                      outline: transparent solid 0;
                      border: transparent;
                      transition-duration: 600ms;
                      transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  :global {

    .swiper-button-next,
    .swiper-button-prev {
      color: rgb(92, 255, 231);

      @media (max-width: 991px) {
        top: var(--swiper-navigation-top-offset, 30%);
      }

      @media (max-width: 576px) {
        top: var(--swiper-navigation-top-offset, 25%);
      }
    }

    .swiper-button-next:after,
    .swiper-button-prev:after {
      @media (max-width: 991px) {
        font-size: 25px !important;
      }
    }

    .swiper-pagination-bullet-active {
      background: rgb(92, 255, 231)
    }

    // .swiper-pagination-bullet {
    //   margin: 30px;
    //   width: var(--swiper-pagination-bullet-width, var(--swiper-pagination-bullet-size, 40px));
    //   height: var(--swiper-pagination-bullet-height, var(--swiper-pagination-bullet-size, 8px));
    //   border-radius: var(--swiper-pagination-bullet-border-radius, 10%)
    // }

  }
}