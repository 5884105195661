.availableCars {
    .backgroundRight {
        top: -30%;
    }

    .backgroundLeft {
        bottom: -35%;

        @media (max-width: 576px) {
            bottom: 0;
        }
    }

    .container {

        .row {
            column-gap: 10px;

            .colLeft {
                border: 1px solid rgb(92, 255, 231);
                width: 30%;
                margin-bottom: 1rem;

                @media (max-width: 991px) {
                    width: 100%;
                }

                .img {
                    object-fit: contain;
                    aspect-ratio: 2/2;
                    width: 100%;
                }
            }


        }
    }
}