.JoinUs {
    background-image: url("../../images/cars/bg8.jpeg");
    background-repeat: no-repeat;
    background-size: 100%;
    min-height: 600px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .container {
        .row {
            .column_left {
                .heading {
                    width: 50%;
                    text-align: center;
                }
            }
        }
    }
}