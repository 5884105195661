.AboutCars {
    background: linear-gradient(90deg, #5cffe722 37.07%, #000000ca 100.66%);

    .container {
        .row {
            display: flex;
            justify-content: center;
            align-items: center;

            .colLeft {

                .headingOne {
                    justify-content: flex-start;
                }
            }

        }
    }
}